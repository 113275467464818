<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">
        <b-icon icon="server" />
        {{ qre.name }} Logs
      </h1>
      <b-button
        class="mr-2 shadow-sm"
        variant="primary"
        pill
        @click="$router.go(-1)"
      >
        <b-icon-arrow90deg-left class="mr-2" />
        {{ $t("back") }}
      </b-button>
    </div>

    <b-card
      border-variant="light"
      class="shadow-sm card-rounded custom-card-padding mb-4"
    >
      <div class="mb-4">
        <p class="custom-card-title">Pick Interval</p>
      </div>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="handleSubmit(getLogs)">
          <div
            class="d-lg-flex flex-lg-row justify-content-lg-between flex-md-column flex-sm-column align-items-sm-start"
          >
            <div
              class="wrapper d-flex justify-content-lg-between align-items-end flex-md-grow-1 flex-sm-grow-1 mr-lg-3 mb-4"
            >
              <ValidationProvider
                id="dateFrom"
                class="flex-grow-1"
                v-slot="{ errors, valid, pristine }"
                :rules="{
                  check_date_from: {
                    dateTo: datepickerTo,
                  },
                }"
              >
                <b-form-group
                  id="group-dateFrom"
                  label="From"
                  label-for="from-datepicker"
                  class="position-relative"
                >
                  <b-form-datepicker
                    id="from-datepicker"
                    v-model="datepickerFrom"
                    :max="maxDate"
                    :state="getValidationState(errors, valid, pristine)"
                    close-button
                    reset-button
                    today-button
                    value-as-date
                    locale="en-GB"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                  <b-form-invalid-feedback
                    id="live-feedback-dateFrom"
                    class="position-absolute"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                id="timeFrom"
                class="flex-grow-1"
                v-slot="{ errors, valid, pristine }"
                :rules="{
                  check_time_from: {
                    dateFrom: datepickerFrom,
                    dateTo: datepickerTo,
                    timeTo: timepickerTo,
                    convertToMilliseconds: convertToMilliseconds,
                  },
                }"
              >
                <b-form-group id="group-timeFrom" class="position-relative">
                  <b-form-timepicker
                    class="ml-2"
                    v-model="timepickerFrom"
                    :hour12="false"
                    :state="getValidationState(errors, valid, pristine)"
                    locale="en"
                    reset-button
                    show-seconds
                  />

                  <b-form-invalid-feedback
                    id="live-feedback-timeFrom"
                    class="position-absolute"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div
              class="wrapper d-flex justify-content-lg-between align-items-end flex-md-grow-1 flex-sm-grow-1 mr-lg-3 mb-4"
            >
              <ValidationProvider
                id="dateTo"
                class="flex-grow-1"
                v-slot="{ errors, valid, pristine }"
                :rules="{
                  check_date_to: {
                    dateFrom: datepickerFrom,
                  },
                }"
              >
                <b-form-group
                  id="group-dateTo"
                  label="To"
                  label-for="to-datepicker"
                  class="position-relative"
                >
                  <b-form-datepicker
                    id="to-datepicker"
                    v-model="datepickerTo"
                    :max="maxDate"
                    :state="getValidationState(errors, valid, pristine)"
                    close-button
                    reset-button
                    today-button
                    value-as-date
                    locale="en-GB"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />

                  <b-form-invalid-feedback
                    id="live-feedback-dateTo"
                    class="position-absolute"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                id="timeTo"
                class="flex-grow-1"
                v-slot="{ errors, valid, pristine }"
                :rules="{
                  check_time_to: {
                    dateFrom: datepickerFrom,
                    dateTo: datepickerTo,
                    timeFrom: timepickerFrom,
                    convertToMilliseconds: convertToMilliseconds,
                  },
                }"
              >
                <b-form-group id="group-timeTo" class="position-relative">
                  <b-form-timepicker
                    class="ml-2"
                    v-model="timepickerTo"
                    :hour12="false"
                    :state="getValidationState(errors, valid, pristine)"
                    locale="en"
                    reset-button
                    show-seconds
                  />
                  <b-form-invalid-feedback
                    id="live-feedback-timeTo"
                    class="position-absolute"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-4">
            <b-button
              pill
              variant="primary"
              @click="getLogs"
              :disabled="invalid"
            >
              <b-icon class="mr-1" icon="arrow-repeat" />
              Execute
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>

    <b-card
      border-variant="light"
      class="shadow-sm card-rounded custom-card-padding"
    >
      <div class="d-flex justify-content-between align-items-center mb-3">
        <p class="custom-card-title mb-0">Logs</p>
        <b-button variant="outline-primary" pill @click="maximizeScreen">
          <b-icon class="mr-1" icon="arrows-fullscreen" />
          Fullscreen
        </b-button>
      </div>
      <div class="logs-card">
        <div
          ref="fullscreenDiv"
          :class="logs.length === 0 ? 'full-screen-div' : ''"
          class="bg-dark p-3 overflow-auto h-100"
        >
          <b-table
            :busy="loadingLogs"
            :fields="fieldsLogs"
            :items="logs"
            borderless
            class="mb-0 logs-table"
            dark
            data-cy="admin-logs-table"
            striped
            thead-class="hidden-header"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(icon)="">
              <b-icon icon="chevron-right"></b-icon>
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
});

extend("check_date_from", {
  params: ["dateTo"],
  validate: (value, { dateTo }) => {
    return value.getTime() <= dateTo.getTime();
  },
  message: "Start date must be <= than end date",
});

extend("check_date_to", {
  params: ["dateFrom"],
  validate: (value, { dateFrom }) => {
    return dateFrom.getTime() <= value.getTime();
  },
  message: "End date must be >= than start date",
});

extend("check_time_from", {
  params: ["dateFrom", "dateTo", "timeTo", "convertToMilliseconds"],
  validate: (value, { dateFrom, dateTo, timeTo, convertToMilliseconds }) => {
    const timeStampFrom = dateFrom.getTime() + convertToMilliseconds(value);
    const timeStampTo = dateTo.getTime() + convertToMilliseconds(timeTo);

    return timeStampFrom < timeStampTo;
  },
  message: "Start time must be < than end time",
});

extend("check_time_to", {
  params: ["dateFrom", "dateTo", "timeFrom", "convertToMilliseconds"],
  validate: (value, { dateFrom, dateTo, timeFrom, convertToMilliseconds }) => {
    const timeStampFrom = dateFrom.getTime() + convertToMilliseconds(timeFrom);
    const timeStampTo = dateTo.getTime() + convertToMilliseconds(value);

    return timeStampFrom < timeStampTo;
  },
  message: "End time must be > than start time",
});

export default {
  name: "QreLogs",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    // Set from date
    const fromDate = new Date(today);
    fromDate.setDate(fromDate.getDate() - 1);

    // set timepicker
    const time = now.toLocaleTimeString([], { hour12: false });

    return {
      id: this.$route.params.id,
      qre: {},
      logs: [],
      loadingLogs: true,
      datepickerFrom: fromDate,
      timepickerFrom: time,
      datepickerTo: today,
      timepickerTo: time,
      maxDate: today,
      fieldsLogs: [
        { key: "icon", label: "" },
        { key: "timestamp", label: "", tdClass: "text-primary" },
        {
          key: "msg",
          label: "",
          tdStyle: "word-wrap: break-word; white-space: normal",
        },
      ],
    };
  },

  async mounted() {
    await this.getQre();
    await this.getLogs();
  },
  methods: {
    maximizeScreen() {
      this.$refs.fullscreenDiv.requestFullscreen();
    },

    getValidationState(errors, valid, pristine) {
      if (errors[0]) {
        return false;
      } else if (valid) {
        if (pristine) {
          return null;
        } else {
          return true;
        }
      } else {
        return null;
      }
    },

    convertToMilliseconds(time) {
      const splitTime = time.split(":");
      const hours = parseInt(splitTime[0]);
      const minutes = parseInt(splitTime[1]);
      const seconds = parseInt(splitTime[2]);

      return (hours * 60 * 60 + minutes * 60 + seconds) * 1000;
    },

    async getQre() {
      try {
        const { data: qre } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/qres/${this.$route.params.id}`,
          {
            errors: {
              404: () => true,
            },
          }
        );
        this.qre = qre;
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      }
    },

    async getLogs() {
      this.loadingLogs = true;

      const timeStampFrom =
        this.datepickerFrom.getTime() +
        this.convertToMilliseconds(this.timepickerFrom);

      const timeStampTo =
        this.datepickerTo.getTime() +
        this.convertToMilliseconds(this.timepickerTo);

      try {
        const { data } = await this.$http.get(
          `${this.$cfg.BASE_QRE_MANAGER_URL}/v0/loki/${this.$route.params.id}/logs`,
          {
            params: {
              endTimestamp: timeStampTo,
              startTimestamp: timeStampFrom,
            },
          }
        );

        this.logs = data.map((el) => {
          const newEl = {};

          let date = new Date(el.timestampInMillis);
          // to set and show milliseconds
          let options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            fractionalSecondDigits: 3,
          };

          newEl.timestamp = date
            .toLocaleString("en-GB", options)
            .replaceAll("/", "-")
            .replaceAll(",", " ");

          newEl.msg = el.message;

          return newEl;
        });
      } catch (err) {
        if (err.response.status === 404) {
          await this.$router.push("/admin/qres");
        }
        if (err.response) console.log(err.response);
      }
      this.loadingLogs = false;
    },
  },
};
</script>

<style scoped>
.custom-card-title {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: #7e868b;
}

.logs-card {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
  border-radius: 0.25rem;
}

.logs-table {
  border-radius: 5px;
}

.full-screen-div {
  height: 100% !important;
}

.hidden-header {
  display: none;
}

::v-deep .form-control.is-invalid,
::v-deep .form-control.is-valid {
  padding-right: 0 !important;
}

#live-feedback-timeFrom,
#live-feedback-timeTo {
  margin-left: 0.5rem;
}

@media (max-width: 460px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start !important;
    align-items: stretch !important;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
  .b-form-btn-label-control.form-control {
    margin-left: 0 !important;
  }
  #dateFrom,
  #dateTo {
    margin-bottom: 2rem;
  }
  #live-feedback-timeFrom,
  #live-feedback-timeTo {
    margin-left: 0 !important;
  }
}
</style>
